.home {
  width: 100%;
  /* height: 1200px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f8f4f4;
  color: #083c3c;
  /* height: calc(100vh); */
  /* padding-bottom: 200px; */
}



.random {
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #3e497a;
}

.about {
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f8f4f4;
  color: #083c3c;
}

.home h1 {
  font-size: 60px;
  margin-bottom: 20px;
  color: #085c64;
}

.home p {
  width: 70%;
  line-height: 24px;
  color: #083c3c;
}

.about .prompt {
  font-size: 28px;
  margin-bottom: 40px;
 
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icons a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #085c64;
  color: #f8f4f4;
  transition: all 0.2s ease-in-out;
  margin-bottom: 500px;
}

.icons a:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}



.list {
  list-style: none;
  width: 60%;
  padding: 0;
}

.item {
  margin-bottom: 20px;
}

.item h3 {
  font-size: 24px;
  color: #083c3c;
  margin-bottom: 10px;
}

.list span {
  font-size: 20px;
  color: #3e497a;
}

.homeStack{
  display: flex;
  flex-direction: column;
}

.lameLink{
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-family: 'League Spartan';
  color: #f8f4f4;
  font-size: 25px;
  padding-top: 0.5em;
  text-align: left;
  text-decoration: none;
  padding-bottom: 0.5em;
}


.lameLinkBack {
  text-decoration: none;
  margin-right: 20px;
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: #085c64;
  border-radius: 40px;
  

}

.lameLinkStack{
  display: flex;
  flex-direction: row;
}

.lameLinkArrow img {
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 1rem;
  margin-left: 1rem;
  object-fit: cover;
}
.lameLinkArrow{ 
  width: 40px;
  height: 40px;
}

.lameLinkText {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-family: 'League Spartan';
  color: #f8f4f4;
  font-size: 25px;
  text-align: left;
  font-weight: bold;
  padding-top: 10px;

}

.lameLink a, .lameLink a:visited, .lameLink a:hover, .lameLink a:active {
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .lameLink {
    display: none;
  }
}

.giantImage{

width: 250px;
height: 250px;
box-sizing: border-box;
margin-top: 50px;

}

.giantImage img {
  display: flex;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  /* margin-top: 10px; */
}