.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f4f4;
  color: #083c3c;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.2rem;
  height: 70px;
}

.links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  text-decoration: none;
}

.links a {
  
  color: #083c3c;
  text-decoration: none;
  margin: 0 1rem;
  
  
}


.links a:hover {
color: #085c64;

}

.links2 {
  margin-left: 10px;
}

.toggleButton {
  display: none;
}
.navbarStack {
display: flex;
flex-direction: column;
align-items: center;
grid-column: 1;
grid-row: 1;
}

.bigNav {
  display: grid;
  background-color: #f8f4f4;
}


.dividerBar{
  background-color: #085c64;
  height: 4px;
  width: 95%;
  border-radius: 4px;
}

.dividerBar2{
  background-color: #085c64;
  height: 30px;
  width: 3px;
  border-radius: 3px;
}

.contactButton{
  text-decoration: none;
  margin-right: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: #085c64;
  border-radius: 40px;
}

.contactLink {
  text-decoration: none;
  color: #f8f4f4;
  font-weight: bold;
  ;
}

/* 
.lameLink{
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  font-family: 'League Spartan';
  color: #f8f4f4;
  font-size: 18px;
  padding-top: 0.5em;
  text-align: left;
  text-decoration: none;
  padding-bottom: 0.5em;
} */


/* .lameLinkBack {
  text-decoration: none;
  margin-right: 20px;
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: #085c64;
  border-radius: 40px;
  

}

.lameLinkStack{
  display: flex;
  flex-direction: row;
}

.lameLinkArrow img {
  display: flex;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 1rem;
  margin-left: 1rem;
  object-fit: cover;
}
.lameLinkArrow{ 
  width: 40px;
  height: 40px;
}

.lameLinkText {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-family: 'League Spartan';
  color: #f8f4f4;
  font-size: 30px;
  text-align: left;
  font-weight: bold;
  padding-top: 12px;
  padding-left: 10px;
  padding-right: 30px;
} */



@media only screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    padding: 2rem;
  }

  .toggleButton {
    display: block;
    position: absolute;
    top: 2rem;
    right: 2rem;
    font-size: 2rem;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
  }

  .links {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .links a {
    margin: 1rem 0;
  }

  #close {
    display: none;
  }

  #open {
    display: block;
  }
}

.homeButton {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 10px;
}

.homeButton img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 1rem;
  object-fit: cover;
}




.homeButton h3 {
  margin: 0;
  font-size: 1.4rem;
  color: #085c64;
}

