.projects-gallery {
    display: grid;
    grid-template-columns: repeat(2, 500px); /* Creates exactly two 500px columns */
    gap: 60px;
    width: calc(min(100vw, 1500) - 120px);; /* Adjusts the width to account for the gap */
    justify-items: center;
    padding-top: 20px;
  }
  
  .project-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border: 3px solid #085c64; /* Added border with the specified color */
    border-radius: 15px; /* Rounded corners for the box */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 470px; /* Adjusted to fit within the 500px columns */
  }
  
  .project-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 5px;
  }
  
  .caption {
    text-align: left;
    padding-left: 10px;
  }
 
  
  
  .caption h3 {

    font-size:30px;
    font-family: 'League Spartan';
    font-weight: bold;
    color: #085c64;
  }
  
  .caption p {
    color: #083c3c; /* Changed description text color */
  }
  
  .learn-more-btn {
    margin-top: 0;
    align-self: center;
    padding: 10px 15px;
    background-color: #085c64;
    color: #f8f4f4;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 5px; /* Slightly rounded corners for the button */
  }
  
  .learn-more-btn:hover {
    background-color: #083c3c;
  }
  