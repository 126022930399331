.navigation-slider {
    position: relative; /* Needed to position the highlight element absolutely */
    display: flex;
    justify-content: center; /* Centers the tabs within the navigation slider */
    width: 100%;
    height: 100%;
    border: 2px solid #083c3c; /* Light grey border around the whole navigation slider */
    border-radius: 10px; /* Rounded corner for the navigation slider */
    margin: 10px 0; /* Adds vertical space around the navigation slider */
  /* Increased padding for more space inside the border */
    box-sizing: border-box; /* Ensures border width is included in width calculation */
}

.tabs {
    display: flex;
    width: 100%; /* Adjust width to account for padding */
    position: relative; /* Relative to the highlight */
    border-radius: 8px; /* Rounded corners for tabs */
    padding: 4px;
    overflow: hidden; /* Ensures the highlight does not spill outside the rounded corners */
}

.tab {
    cursor: pointer;
    background-color: transparent; /* Background color for normal state */
    flex-grow: 1;
    text-align: center;
    border: none;
    padding: 10px 0;
    transition: color 0.3s ease, background-color 0.3s ease;
    z-index: 3; /* Ensure the tab text is above the highlight */
    font-family: 'League Spartan'; /* Adjust the font family as needed */
    font-size: 18px; /* Adjust the font size as needed */
    color: #085c64; /* Font color for normal state */
    font-weight: bold;
}

.tab.active {
    color: #fff; /* Font color for active state */
}

.highlight {
    position: absolute;
    top: 4px; /* Adjust to fit within the padding of the navigation slider */
    left: 8px; /* Adjust to fit within the padding of the navigation slider */
    height: calc(100% - 8px); /* Adjust height to fit within the padding */
    background-color: #085c64;
    transition: left 0.5s ease, width 0.5s ease;
    z-index: 2;
    border-radius: 6px; /* Slightly less than .tabs to fit inside */
    opacity: 1; /* Make it visible from the start */
}
