.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  font-family: 'League Spartan';
  background-color: #f8f4f4;
}

body {
  padding: 0;
  margin: 0;
}
