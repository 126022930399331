.otherContent {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f8f4f4;
  color: #083c3c;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  /* margin: 20px; */
  padding-top: 20px;
  /* border: 1px solid #ccc; */
}

p {
  align-items: center;
  width: 90%;
}

.otherContent-sidebar {
  width: 200px;
  margin-right: 10px;
}

.otherContent-sidebar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.otherContent-sidebar ul li {
  margin-bottom: 10px;
}

.otherContent-sidebar ul li a {
  text-decoration: none;
  color: #085c64;
}

.otherContent-sidebar ul li a:hover {
  color: #083c3c;
}

.otherContent-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}


.otherContent-content h2{
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  color: #085c64;
}
