.section {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 30px;
  font-weight: bold;
}

.red {
  background-color: #ffacac;
}

.blue {
  background-color: #9797fa;
}

.green {
  background-color: #90ec90;
}
