.portfolio {
  width: 100%;
  /* height: calc(100vh - 0px); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f8f4f4;
  color: #085c64;
  display: flex;
  /* align-items: flex-start; */
  padding-bottom: 100px;
  margin-right: 0px;
  /* margin: 20px; */
  padding-top: 20px;
  /* border: 1px solid #ccc; */
}

.portfolio-topbar {
  width: 800px;
  margin: 0 auto; /* This will center the topbar */
}

.portfolio-topbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.portfolio-topbar ul li {
  margin-bottom: 10px;
  /* margin-left: 40px; */
  text-align: center;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  /* padding-left: 20px; */
}

.portfolio-topbar ul li a {
  text-decoration: none;
  color: #085c64;
  text-align: center;
  font-size: 20px;
  /* font-weight: bold; */
  
}

.portfolio-topbar ul li a:hover {
  color: #083c3c;
}

.portfolio-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-right: 20px; */
  justify-items: center;
  width: calc(min(100vw, 1250px));
}



.portfolioTitle {
  display: flex;
  font-family: 'League Spartan';
  font-weight: bold;
  width: 100%;
  box-sizing: border-box;
  padding-left: 4%;
  padding-top: 0.5em;
  text-align: left;
  font-size: 50px;
  color:#085c64;
  
}

.portfolioSubtitle {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-left: 6%;
  padding-top: 1.5em;
  text-align: left;
  font-size:30px;
  font-family: 'League Spartan';
  font-weight: bold;
  color: #083c3c;
}

.portfolioSubtitle2 {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-left: 8%;
  padding-top: 1em;
  text-align: left;
  font-size: 23px;
  font-family: 'League Spartan';
  color: #083c3c;
  text-decoration: underline;
}


.textOnly {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  font-family: 'League Spartan';
  color: #083c3c;
  font-size: 18px;
  padding-top: 0.5em;
  text-align: left;
  text-indent: 35px;

}

.listTextOnly {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-left: 10%;
  padding-right: 10%;
  font-family: 'League Spartan';
  color: #083c3c;
  font-size: 18px;
  padding-top: 0.5em;
  text-align: left;

}
.listTextOnly ol{
  list-style-type: disc;
  padding: 0;
  margin: 0;

}

.listTextOnly ol li {
  padding-left: 5px; /* Indents the text away from the bullet point */
}




.boldTextOnly {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 8%;
  font-family: 'League Spartan';
  color: #083c3c;
  font-size: 18px;
  padding-top: 1.0em;
  text-align: left;
  font-weight: bold;

}

.photoslider {
  width: 100%;
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 8%;
  display: flex;
  flex-direction: row;
  padding-top: 1.0em;



}

.NextPageLink a, .NextPageLink a:visited, .NextPageLink a:hover, .NextPageLink a:active {
  text-decoration: none;
}

.NextPageLink a, .NextPageLink a:visited, .NextPageLink a:hover, .NextPageLink a:active {
  text-decoration: none;
}

.psOnly {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-left: 10%;

}

.justText {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-family: 'League Spartan';
  color: #083c3c;
  font-size: 18px;
  text-align: left;
  text-indent: 35px;
  
  
}

.justTitle {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-family: 'League Spartan';
  color: #083c3c;
  font-size: 18px;
  text-align: left;
  padding-bottom: 0.5em;
  font-weight: bold;
  
}

.titleAndText {
display: flex;
flex-direction: column;
width: 100%;
}


.TextLPSR {
  width: 100%;
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 8%;
  display: flex;
  flex-direction: row;
  padding-top: 1.0em;


}
.TextRPSL {

  display: flex;
  flex-direction: row;
  

}


.boldy {

  font-weight: bold;
}

.contactOuter{
  display: flex;

}


.contact{
display: flex;
flex-direction: row;
border-radius: 10px;
width: 600px;
box-sizing: border-box;
padding: 20px;
margin-top: 10px;
margin-bottom: 10px;
border-style: solid;
border-color: #085c64;
border-width: 3px;
}

.contactImage img{
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-right: 1rem;
  object-fit: cover;
  

}

.contactInfo{
  display: flex;
  flex-direction: column;
  
}

.contactName {
  font-family: 'League Spartan';
  color: #083c3c;
  font-size: 22px;
  text-align: left;
  padding: 4px;
  font-weight: bold;
  margin-top: 16px;

}

.contactDescription{
  font-family: 'League Spartan';
  color: #083c3c;
  font-size: 18px;
  text-align: left;
  padding: 4px;
  font-style: italic;
  
}

.contactcontact{
  font-family: 'League Spartan';
  color: #083c3c;
  font-size: 18px;
  text-align: left;
  padding: 4px;

}

.contactIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-left: 10px;
}



.contactIcon a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #085c64;
  color: #f8f4f4;
  transition: all 0.2s ease-in-out;
  /* margin-bottom: 500px; */
}

.contactIcon a:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.NextPageLink{
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  font-family: 'League Spartan';
  color: #f8f4f4;
  font-size: 18px;
  padding-top: 5em;
  text-align: center;
  text-decoration: none;
  padding-bottom: 0.5em;

}


.lameLink{
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-left: 8%;
  padding-right: 10%;
  font-family: 'League Spartan';
  color: #f8f4f4;
  font-size: 18px;
  padding-top: 0.5em;
  text-align: left;
  text-decoration: none;
  padding-bottom: 0.5em;
}


.lameLinkBack {
  text-decoration: none;
  margin-right: 20px;
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: #085c64;
  border-radius: 40px;
  

}

.lameLinkStack{
  display: flex;
  flex-direction: row;
}

.lameLinkArrow img {
  display: flex;
  width: 24px;
  height: 24px;
  margin-right: 1rem;
  margin-left: 1rem;
  object-fit: cover;
  padding: 8px;
}
.lameLinkArrow{ 
  width: 40px;
  height: 40px;
}

.lameLinkText {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-family: 'League Spartan';
  color: #f8f4f4;
  font-size: 22px;
  text-align: left;
  font-weight: bold;
  padding-top: 6px;
  text-decoration: none;

}

/* .NextPageButton{

  background-color: #f8f4f4;
  border-style: none;
} */