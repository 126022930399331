.photo-gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Creates 4 columns */
    gap: 20px; /* Space between items */
    padding: 100px; /* Padding around the gallery */
  }
  
  .photo-box {
    border-radius: 10px; /* Optional: rounded corners for each photo box */
    overflow: hidden; /* Ensures the content respects the border radius */
  }
  
  .photo-box img {
    width: 100%; /* Makes images fill their containers */
    height: auto; /* Maintains aspect ratio */
    display: block; /* Removes bottom space/margin of inline elements */
  }
  